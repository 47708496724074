import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { IPopupsPageNavigationHandler, PopupFeatureState } from './types'
import { IFeatureState } from 'thunderbolt-feature-state'
import { FeatureStateSymbol } from '@wix/thunderbolt-symbols'

type popupsPageWillMountHandlerFactory = (
	featureState: IFeatureState<PopupFeatureState>
) => IPopupsPageNavigationHandler

const popupsPageWillMountHandler: popupsPageWillMountHandlerFactory = (featureState) => {
	return {
		appWillLoadPage: () => {
			const state = featureState.get()
			if (state && state.pageWillLoadHandler) {
				state.pageWillLoadHandler()
			}
		},
	}
}

export const PopupsPageNavigationHandler = withDependencies(
	[named(FeatureStateSymbol, 'popups')],
	popupsPageWillMountHandler
)
