import { IStructureAPI, StructureAPI } from '@wix/thunderbolt-symbols'
import { IPopupsAPI } from './types'
import { withDependencies } from '@wix/thunderbolt-ioc'

const popupsAPI = (structureAPI: IStructureAPI): IPopupsAPI => {
	return {
		addPopupToDynamicStructure: (popupPageId) => {
			const wrapperId = structureAPI.getPageWrapperComponentId(popupPageId, popupPageId)
			return structureAPI.addComponentToDynamicStructure(
				'POPUPS_ROOT',
				{
					componentType: 'PopupRoot',
					components: [wrapperId],
				},
				{
					[wrapperId]: {
						componentType: 'PageMountUnmount',
						components: [popupPageId],
					},
				}
			)
		},
		removePopupFromDynamicStructure: (popupPageId) => {
			const wrapperId = structureAPI.getPageWrapperComponentId(popupPageId, popupPageId)
			structureAPI.removeComponentFromDynamicStructure(wrapperId)
			structureAPI.removeComponentFromDynamicStructure('POPUPS_ROOT')
		},
	}
}

export const PopupsAPI = withDependencies([StructureAPI], popupsAPI)
