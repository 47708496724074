import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	IPageWillMountHandler,
	PageFeatureConfigSymbol,
	ICompEventsRegistrar,
	CompEventsRegistrarSym,
} from '@wix/thunderbolt-symbols'
import { name, PopupsSymbol } from './symbols'
import { IPopups, PopupsPageConfig } from '.'

type PopupsCloseBehaviors = (
	featureConfig: PopupsPageConfig,
	popupApi: IPopups,
	compEventsRegistrar: ICompEventsRegistrar
) => IPageWillMountHandler

const popupsCloseBehaviors: PopupsCloseBehaviors = (
	featureConfig,
	popupApi,
	compEventsRegistrar
): IPageWillMountHandler => {
	return {
		pageWillMount: (pageId) => {
			// close popup on background click
			if (featureConfig.closeOnOverlayClick) {
				compEventsRegistrar.register(pageId, 'onClick', popupApi.closePopupPage)
			}
			// close popup on close button click
			featureConfig.closePopupCompIds.forEach((compId) => {
				compEventsRegistrar.register(compId, 'onClick', popupApi.closePopupPage)
			})
		},
	}
}

export const PopupsCloseBehaviors = withDependencies(
	[named(PageFeatureConfigSymbol, name), PopupsSymbol, CompEventsRegistrarSym],
	popupsCloseBehaviors
)
